import * as React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { LiteYoutubeEmbed } from "react-lite-yt-embed";
import Link from "./link";

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const [{ value }] = node.content;

      if (value !== "" || node.content.length > 1) {
        return <p>{children}</p>;
      }

      return null;
    },

    [INLINES.HYPERLINK]: (node, children) => (
      <Link href={node.data.uri}>{children}</Link>
    ),
  },

  renderText: (text) => {
    return text
      .split("\n")
      .flatMap((text, index) => [index > 0 && <br key={index} />, text]);
  },
};

const getYoutubeId = (url) => {
  let id = "";
  url = url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    id = url[2].split(/[^0-9a-z_-]/i);
    id = id[0];
  } else {
    id = url;
  }
  return id;
};

const CompareVideo = ({ video }) => {
  if (!video) return null;

  const id = getYoutubeId(video.url);

  return (
    <div className="compare-video">
      {video.intro && <div>{renderRichText(video.intro, renderOptions)}</div>}
      <figure>
        <LiteYoutubeEmbed id={id} noCookie mute={false} lazyImage />
      </figure>
    </div>
  );
};

export default CompareVideo;
