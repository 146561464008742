import * as React from "react";
import { FormattedMessage } from "react-intl";

const Health = () => (
  <svg
    width={41}
    height={38}
    viewBox="0 0 41 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>
      <FormattedMessage id="compareHealthCondition" />
    </title>
    <path
      d="M28.4333 5.71277e-05C25.5125 -0.00863466 22.6832 1.01895 20.4487 2.90006C18.2138 1.01876 15.3841 -0.00882705 12.4628 5.71277e-05C9.15828 0.00412962 5.99029 1.31883 3.65387 3.65574C1.31744 5.99264 0.00339268 9.1609 0 12.4654C0 25.4795 19 36.568 19.809 37.0295C20.0039 37.1421 20.225 37.2013 20.45 37.2013C20.675 37.2013 20.8961 37.1421 21.091 37.0295C21.9 36.5642 40.9 25.4795 40.9 12.4654C40.8963 9.16034 39.5816 5.99169 37.2444 3.65475C34.9072 1.31781 31.7384 0.00345044 28.4333 5.71277e-05ZM20.4487 34.4206C17.1846 32.4013 2.5641 22.7757 2.5641 12.4654C2.56215 10.4983 3.14672 8.57526 4.24313 6.94201C5.33953 5.30876 6.89798 4.03948 8.71934 3.29635C10.5407 2.55322 12.5423 2.36998 14.4683 2.77005C16.3943 3.17012 18.1573 4.13534 19.5321 5.54236C19.7785 5.77914 20.107 5.91137 20.4487 5.91137C20.7905 5.91137 21.119 5.77914 21.3654 5.54236C22.7401 4.13534 24.5031 3.17012 26.4291 2.77005C28.3552 2.36998 30.3567 2.55322 32.1781 3.29635C33.9995 4.03948 35.5579 5.30876 36.6543 6.94201C37.7507 8.57526 38.3353 10.4983 38.3333 12.4654C38.3333 22.7629 23.7115 32.3988 20.4487 34.4206Z"
      fill="currentColor"
    />
    <path
      d="M30.7055 14.754H25.5773C25.3561 14.754 25.1386 14.8113 24.9461 14.9202C24.7536 15.0292 24.5925 15.1861 24.4786 15.3758L22.3376 18.9437L20.4235 9.36934C20.3725 9.11593 20.2462 8.88383 20.0611 8.70348C19.8759 8.52314 19.6405 8.40297 19.3859 8.35875C19.1312 8.31453 18.8691 8.34831 18.634 8.45567C18.3989 8.56304 18.2017 8.73896 18.0683 8.96037L14.5953 14.754H10.1927C9.85267 14.754 9.52658 14.889 9.28615 15.1295C9.04572 15.3699 8.91064 15.696 8.91064 16.036C8.91064 16.376 9.04572 16.7021 9.28615 16.9426C9.52658 17.183 9.85267 17.3181 10.1927 17.3181H15.3209C15.5421 17.318 15.7596 17.2607 15.9521 17.1518C16.1446 17.0428 16.3057 16.8859 16.4196 16.6963L18.5606 13.1283L20.4735 22.7027C20.5241 22.9564 20.6504 23.1889 20.8356 23.3696C21.0209 23.5503 21.2565 23.6706 21.5115 23.7149C21.7664 23.7591 22.0288 23.7252 22.2641 23.6175C22.4995 23.5099 22.6967 23.3335 22.8299 23.1117L26.303 17.3181H30.7055C31.0455 17.3181 31.3716 17.183 31.6121 16.9426C31.8525 16.7021 31.9876 16.376 31.9876 16.036C31.9876 15.696 31.8525 15.3699 31.6121 15.1295C31.3716 14.889 31.0455 14.754 30.7055 14.754Z"
      fill="currentColor"
    />
    <path
      d="M35.4616 15.1261C35.2506 14.911 34.9715 14.7758 34.6719 14.7437C34.3723 14.7116 34.0709 14.7846 33.8191 14.9501C33.5674 15.1157 33.381 15.3635 33.2917 15.6513C33.2025 15.9391 33.216 16.249 33.3299 16.5279C33.4438 16.8068 33.6511 17.0375 33.9163 17.1805C34.1815 17.3236 34.4882 17.37 34.7838 17.312C35.0795 17.2539 35.3458 17.095 35.5373 16.8623C35.7287 16.6297 35.8334 16.3377 35.8334 16.0364C35.8291 15.6969 35.6962 15.3716 35.4616 15.1261Z"
      fill="currentColor"
    />
    <path
      d="M5.43638 15.1253C5.25557 15.3047 5.13226 15.5338 5.08218 15.7834C5.03209 16.0331 5.05749 16.2921 5.15514 16.5272C5.25278 16.7624 5.41826 16.9632 5.63047 17.104C5.84267 17.2447 6.09199 17.3191 6.34664 17.3177C6.68603 17.3127 7.01107 17.1799 7.25689 16.9459C7.49304 16.7014 7.62616 16.3755 7.62869 16.0356C7.63017 15.781 7.55578 15.5316 7.41501 15.3194C7.27424 15.1072 7.07346 14.9417 6.83828 14.8441C6.60309 14.7465 6.34416 14.7211 6.09448 14.7711C5.8448 14.8212 5.6157 14.9445 5.43638 15.1253Z"
      fill="currentColor"
    />
  </svg>
);

export default Health;
