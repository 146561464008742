import * as React from "react";
import { graphql } from "gatsby";
import { useIntl } from "react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import Compare from "../components/compare";

const LevelsPage = ({ data }) => {
  const { formatMessage } = useIntl();
  const title = formatMessage({ id: "productsCompare" });

  return (
    <Layout>
      <Seo title={title} />
      <Hero title={title} />
      <Compare video={data.video} />
    </Layout>
  );
};

export default LevelsPage;

export const query = graphql`
  query ($locale: String!) {
    video: contentfulYoutubeVideo(
      node_locale: { eq: $locale }
      contentful_id: { eq: "7dpZWDejQHcE2Lhj8WB7x0" }
    ) {
      intro {
        raw
      }
      url
    }

    translations: contentfulTranslations(node_locale: { eq: $locale }) {
      translations {
        key
        value
      }
    }
  }
`;
